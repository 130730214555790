import type { Post } from "~/sdk/qs1/index.server";
import type { IndexLoader } from "../../types";

import { useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Container, Flex, GridV2, Section } from "sparta";
import useBreakpoint from "~/utils/hooks/useBreakpoint";
import LargeModule from "./LargeModule";
import SmallModule from "./SmallModule";

type FeaturedPosts = {
  [title: string]: Post;
};

export default function FeaturedHero() {
  const { featured: posts } = useLoaderData<IndexLoader>();

  // state
  const [featured, setFeatured] = useState<FeaturedPosts>({});
  const [selected, setSelected] = useState("");

  // window size
  const isMobile = useBreakpoint("mobile");

  // on mount, collect all featured posts
  useEffect(() => {
    // collect featured posts
    const featuredPosts: FeaturedPosts = {};

    // define first featured variable
    let firstFeatured = "";

    // loop through all posts and add the featured posts
    for (const article of posts) {
      if (article.featured) {
        if (!firstFeatured) firstFeatured = article.title;
        featuredPosts[article.title] = article as unknown as Post;
      }
    }

    // set the featured posts
    setFeatured(featuredPosts);

    // set the first featured as the selected article
    if (firstFeatured) setSelected(firstFeatured);
  }, [posts]);

  return (
    <Container overflowY="hidden" overflowX="hidden">
      <Flex align="center" justify="center">
        <GridV2
          columns={{
            initial: "1",
            md: "2",
            lg: "2fr 1fr",
          }}
          rows={{
            initial: "2",
            md: "1",
          }}
          gap="2"
          width="100%"
          height="430px"
        >
          <LargeModule article={featured[selected]} />
          <Flex
            direction={{
              initial: "row",
              md: "column",
            }}
            id="featured-col"
          >
            {Object.keys(featured).map((title) =>
              title !== selected ? <SmallModule key={title} article={featured[title]} /> : null,
            )}
          </Flex>
        </GridV2>
      </Flex>
    </Container>
  );
}
